import React, { useEffect } from 'react'

import Cookies from 'js-cookie'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/index'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Seperator, Button, ButtonColors, Paragraph, Heading, Link, Message, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SuccessResponse as ReactFacebookLoginInfo, FailResponse as ReactFacebookLoginFail } from '@greatsumini/react-facebook-login'
import { useGetAppQuery } from '@hooks/api'
import { SiteHelper } from '@lib/SiteHelper'
import { Form, useForm, CheckBoxInput, TextInput, LogInModalDisplayType, LoginModalDisplayStatus, SocialLoginData, SocialLoginType } from '@molecules/index'
import { FacebookLoginButton, GoogleLoginButton, NativeFacebookLoginButton, NativeGoogleLoginButton } from '@organisms/index'
import { CredentialResponse, TokenResponse, useGoogleOneTapLogin } from '@react-oauth/google'
import { MobileOSTypeEnum, SsoProviderEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
 
  width: 100%;

  .form-button {
    width: 100%;
    ${ResponsivePXValue('margin', '12px 0 16px 0')}
  }

  .platform-button {
    width: 100%;
  }

  .title {
    ${ResponsivePXValue('margin', '0 0 14px 0')}
  }
  .join {
    ${ResponsivePXValue('margin-bottom', '12px')}
  }
  .by-creating {
    text-align: center;
    ${ResponsivePXValue('padding', '16px 0 12px 0')}
  }
  .by-selecting {
    text-align: center;
  }
  .text-button {
    padding: 0;
    position: relative;
    ${ResponsivePXValue('top', { mobile: '-1px', tablet: '-1px', desktop: '-1px' })}
  }
  .seperator {
    ${ResponsivePXValue('margin', '0 0 12px 0')}
  }
  .input {
    ${ResponsivePXValue('margin-bottom', '10px')}
  }
  .footer {
    ${ResponsivePXValue('padding', '24px 0 0 24px')}
  }
`

const SocialButtons = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('height', '32px')}

  .platform-button {
    flex-grow: 1;
  }

  .loader {
    margin: auto;
    ${ResponsivePXValue('height', '32px')}
    ${ResponsivePXValue('width', '32px')}
  }
`

const SocialButtonsOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', { mobile: '3px', tablet: '3px', desktop: '3px' })}
`

export interface SignUpData {
  firstname: string
  lastname: string
  email: string
  mobile: string
  password: string
  subscribe: boolean
  isSubscribed?: boolean
}

export interface SocialSignUpData {
  provider: string
  accessToken: string
  accessType: string
}

export interface SignUpFormProps {
  onSignUp: (credentials: SignUpData) => void
  onSocialLogin: (data: SocialLoginData) => void
  onSetDisplayType: (displayType: LogInModalDisplayType) => void
  onSocialLoginError: (error: string) => void
  displayStatus: LoginModalDisplayStatus
  errorMessage?: string
}

export function SignUpForm({ onSignUp, onSocialLogin, onSocialLoginError, onSetDisplayType, displayStatus, errorMessage }: SignUpFormProps): JSX.Element {

  const form = useForm()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApp = appData.app.isNativeApp
  const isApple = appData.app.mobileOSType === MobileOSTypeEnum.APPLE

  const _handleLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string): void => {
    ModalPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.SIGN_UP)
    navigate(href)
  }

  const _handleNativeGoogleLogin = async (token: string): Promise<void> => {
    const provider = isApple ? SsoProviderEnum.GOOGLE_IOS : SsoProviderEnum.GOOGLE_ANDROID
    onSocialLogin({ token, provider, accessType: 'id_token', loginType: SocialLoginType.LOG_IN })
  }

  const _handleNativeFacebookLogin = async (token: string): Promise<void> => {
    onSocialLogin({ token, provider: SsoProviderEnum.FACEBOOK, accessType: '', loginType: SocialLoginType.LOG_IN })
  }

  const _handleGoogleOneTapLogIn = async (user: CredentialResponse): Promise<void> => {
    const token = user?.credential
    _handleSocialLogin(token, SsoProviderEnum.GOOGLE_ONE_TAP, 'id_token')
  }

  const _handleGoogleLogIn = async (user: TokenResponse): Promise<void> => {
    const token = user?.access_token
    _handleSocialLogin(token, SsoProviderEnum.GOOGLE)
  }

  const _handleFacebookLogIn = async (res: ReactFacebookLoginInfo): Promise<void> => {
    const token = res?.accessToken
    _handleSocialLogin(token, SsoProviderEnum.FACEBOOK)
  }

  const _handleSocialLogin = async (token: string, provider: SsoProviderEnum, accessType?: string): Promise<void> => {
    onSocialLogin({ token, provider, accessType, loginType: SocialLoginType.LOG_IN })
  }

  const _handleKeyDown = (e: KeyboardEvent): void => {
    const charCode = e.key || e.code
    if (charCode === 'Enter') {
      form.submit()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', _handleKeyDown)
    return () => document.removeEventListener('keydown', _handleKeyDown)
  }, [])

  useEffect(() => {
    if (Cookies.get('email')) {
      form.setFieldsValue({ email: Cookies.get('email') })
    }
    form?.focus?.('firstname')
  }, [])

  useGoogleOneTapLogin({
    onSuccess: user => _handleGoogleOneTapLogIn(user),
    onError: () => onSocialLoginError('Google One Tap Login Failed'),
  })

  const btnColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.black.pureBlack,
    borderColor: theme.colors.black.pureBlack,
    // Hover
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.black.pureBlack,
    hoverBorderColor: theme.colors.black.pureBlack,
    // Disable
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.grey.athens,
  }

  const buttonTitle = displayStatus === LoginModalDisplayStatus.INPUT || displayStatus === LoginModalDisplayStatus.LOADING
    ? 'SIGN UP'
    : ''
  const buttonIcon = displayStatus === LoginModalDisplayStatus.SUCCESS
    ? LocalIconEnums.CHECK
    : false

  const loading = displayStatus === LoginModalDisplayStatus.LOADING
  const disabled = displayStatus === LoginModalDisplayStatus.SUCCESS

  return (
    <Container>

      <Heading className='title' variant='h3' >Sign Up</Heading>

      <Paragraph
        className='join'
        variant='p2'
        align='left'>
        Join Faithful Nature&apos;s conscious shopping community and change the way you shop, for good.
      </Paragraph>

      <SocialButtons>
        <Choose>
          <When condition={isNativeApp}>
            <NativeGoogleLoginButton
              forSignUp={true}
              onSuccess={_handleNativeGoogleLogin}
              onError={(error: TokenResponse) => onSocialLoginError(error.error_description)} />
            <NativeFacebookLoginButton
              forSignUp={true}
              onSuccess={_handleNativeFacebookLogin}
              onError={(error: TokenResponse) => onSocialLoginError(error.error_description)} />
          </When>
          <Otherwise>
            <GoogleLoginButton
              onSuccess={_handleGoogleLogIn}
              onError={(error: TokenResponse) => onSocialLoginError(error.error_description)}
              forSignUp={true} />
            <FacebookLoginButton
              onSuccess={_handleFacebookLogIn}
              onFail={(error: ReactFacebookLoginFail) => {
                const errorMessage = error.status === 'loginCancelled'
                  ? 'User cancelled the request'
                  : 'Facebook internal error, please try again later'
                onSocialLoginError(errorMessage)
              }}
              forSignUp={true} />
          </Otherwise>
        </Choose>

        <If condition={loading || disabled}>
          <SocialButtonsOverlay />
        </If>
      </SocialButtons>

      <Paragraph className='by-creating' variant='p3' align='center' color={theme.colors.green.bottleGreen}>
        By creating an account through Google or Facebook, you agree to the&nbsp;
        <Link decoration='underline' href='/terms-conditions' onClick={_handleLink}>t&apos;s &amp; c&apos;s</Link>
        &nbsp;and&nbsp;
        <Link decoration='underline' href='/privacy-policy' onClick={_handleLink}>privacy policy</Link>
      </Paragraph>
      <Seperator className='seperator' align='horizontal'></Seperator>

      <Form form={form} onFinish={onSignUp} loading={loading} disabled={disabled}>
        <TextInput
          wrapperClassName='input'
          label='First Name'
          name='firstname'
          placeholder='First name'
          rules={[{ required: true, message: 'Please enter a valid first name', pattern: /^(?=.*[a-zA-Z])[a-zA-Z -]+$/ }]} />
        <TextInput
          wrapperClassName='input'
          label='Last Name'
          name='lastname'
          placeholder='Last name'
          rules={[{ required: true, message: 'Please enter a last name', pattern: /^(?=.*[a-zA-Z])[a-zA-Z -]+$/ }]} />
        <TextInput
          wrapperClassName='input'
          variant='email'
          label='Email'
          name='email'
          placeholder='Email address'
          rules={[{ required: true, message: 'Please enter an email address' }]} />
        <TextInput
          wrapperClassName='input'
          variant='phone'
          label='Mobile Number'
          name='mobile'
          placeholder='Mobile number'
          rules={[{ required: true, message: 'Please enter an mobile number' }]} />
        <TextInput
          wrapperClassName='input'
          variant='password'
          label='Password'
          name='password'
          placeholder='Password'
          rules={[{ required: true, message: 'Please enter a password' }]}
          autoComplete='new-password' />

        <CheckBoxInput
          name='isSubscribed'
          options={[{ value: true, title: 'Subscribe to newsletter for unbeatable discounts, great new products and conscious living tips.' }]}
          showLabel={true}
          loading={displayStatus === LoginModalDisplayStatus.LOADING}
        />

        <Paragraph variant='p3' className='by-selecting' align='center' color={theme.colors.green.bottleGreen}>
          By continuing , you agree to the <Link decoration='underline' href='/privacy-policy' onClick={_handleLink}>privacy policy</Link> and <Link decoration='underline' href='/terms-conditions' onClick={_handleLink}>terms and conditions</Link> and consent to the use of personal information as set out therein.
        </Paragraph>
        <If condition={displayStatus === LoginModalDisplayStatus.ERROR}>
          <Message
            wrapperClassName='messageClass'
            backgroundColor={theme.colors.pink.bridesmaid}
            color={theme.colors.red.cinnabar}
            message={errorMessage} />
        </If>
        <Button
          variant='primary'
          loading={loading}
          colors={btnColors}
          size='medium'
          className='signup-button form-button'
          title={buttonTitle}
          onClick={() => disabled ? null : form.submit()}
          icon={buttonIcon} />

        <LoginContainer>
          <Paragraph
            display='inline'
            align='center'
            variant='p2'>
            Already part of the Faithful community?
          </Paragraph>
          <Button
            variant='text'
            loading={loading || disabled}
            title='Login now'
            size='medium'
            className='text-button'
            onClick={() => disabled ? null : onSetDisplayType(LogInModalDisplayType.LOG_IN)} />
        </LoginContainer>

      </Form>
    </Container>
  )

}
